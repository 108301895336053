<template>
 <basePolicy slug="cookie-policy"/>    
</template>
<script>
import basePolicy from '@/components/basePolicy.vue'
export default {
  components:{
    basePolicy
  },
}
</script>